











































































import Vue from 'vue';
import AppMain from '@/components/AppMain.vue';
import AppForm from '@/components/AppForm.vue';
import LOGIN from '@/graphql/mutations/Login.graphql';
import { required, email } from 'vuelidate/lib/validators';
import validationMessages from '@/utils/validationMessages';
import { GraphQLError } from 'graphql';
import { LoginInput, LoginMutationVariables } from '@/types/schema';

export default Vue.extend({
  name: 'Login',
  components: {
    AppMain,
    AppForm,
  },
  data() {
    return {
      loading: false,
      errors: [] as GraphQLError[],
      form: {
        email: '',
        password: '',
      } as LoginInput,
    };
  },
  validations() {
    return {
      form: {
        email: { required, email },
        password: { required },
      },
    };
  },
  methods: {
    validationMessages,
    async login() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      try {
        this.loading = true;
        this.errors = [];
        await this.$apollo.mutate({
          mutation: LOGIN,
          variables: {
            input: {
              email: this.form.email,
              password: this.form.password,
            },
          } as LoginMutationVariables,
        });
        const { redirect } = this.$route.query;
        this.$router.push(String(redirect) || { name: 'home' });
      } catch ({ graphQLErrors }) {
        this.errors = graphQLErrors;
      } finally {
        this.loading = false;
      }
    },
  },
});
